import React from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ErrorOutlineRounded from "@material-ui/icons/ErrorOutlineRounded";

export const SuccessStep = () => {
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12}>
        <ErrorOutlineRounded></ErrorOutlineRounded>
        <h3>Create employee successfully.- </h3>
      </GridItem>
    </GridContainer>
  );
};
