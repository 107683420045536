import React, { useEffect, useState } from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import { useSelector } from "react-redux";
import axios from "axios";
import jwt_decode from "jwt-decode";

export const ListPurchase = () => {
  //const classes = useStyles();
  const [listFolder, setListFolder] = useState([]);

  const { token } = useSelector((state) => state.auth);
  let { id } = jwt_decode(token);
  let endPoint = "/api/bff/folder/user/" + id;
  useEffect(() => {
    axios
      .get(endPoint, { headers: { "authorization-token": token } })
      .then((response) => {
        console.log(JSON.stringify(response.data.response.object));
        if (Array.isArray(response.data.response.object)) {
          setListFolder(response.data.response.object);
        }
      });
  }, []);
  return (
    <GridContainer>
      {listFolder.map((folder, key) => {
        console.log(folder, key);
      })}
    </GridContainer>
  );
};

export default ListPurchase;
