import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({
  isAuthenticated,
  Component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      component={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/authenticate/login-page" />
        )
      }
    />
  );
};
PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  Component: PropTypes.func.isRequired,
};
