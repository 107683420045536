const urlAuth = "https://api.auth.seth.cl";
const urlDms = "https://api.dms-sobekingenieros.cl";

const fetchSinToken = (endpoint, data, method = "GET") => {
  const url = `${urlAuth}/${endpoint}`;
  if (method === "GET") {
    return fetch(url);
  } else {
    return fetch(url, {
      method,
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
};

const fetchConToken = (endpoint, data, method = "GET", urlType, token) => {
  const url =
    urlType === "dms" ? `${urlDms}/${endpoint}` : `${urlAuth}/${endpoint}`;
  if (method === "GET") {
    return fetch(url, {
      method,
      headers: {
        "authorization-token": token,
      },
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        "Content-type": "application/json",
        "authorization-token": token,
      },
      body: JSON.stringify(data),
    });
  }
};

export { fetchSinToken, fetchConToken };
