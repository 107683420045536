import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { createEmployee, saveEmployee } from "actions/employee";

import Wizard from "components/Wizard/Wizard";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Step1 from "./WizardSteps/Step1";
import Step2 from "./WizardSteps/Step2";
import Step3 from "./WizardSteps/Step3";
import Step4 from "./WizardSteps/Step4";
import Step5 from "./WizardSteps/Step5";

export const WizardEmployee = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { complete, about, account, address } = useSelector(
    (state) => state.employee
  );
  const saveButtonClick = ({ about, account, address }) => {
    console.log("wizard emp saveClick");
    dispatch(saveEmployee(true, about, account, address));
  };
  const finishButtonClick = () => {
    //console.log("finishButtonClick - winzard emp");
    //e.preventDefault();
    //console.log("finish allState: ", about, account, address);
    //console.log("finish complete: ", complete);
    if (complete !== undefined && complete && address !== undefined) {
      dispatch(createEmployee(about, account, address, token));
    }
  };

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={8}>
        <Wizard
          validate
          steps={[
            {
              stepName: "Info Employee",
              stepComponent: Step1,
              stepId: "about",
            },
            {
              stepName: "System Account",
              stepComponent: Step2,
              stepId: "account",
            },
            {
              stepName: "Address Company",
              stepComponent: Step3,
              stepId: "address",
            },
            {
              stepName: "Review",
              stepComponent: Step4,
              stepId: "review",
            },
            {
              stepName: "Finish",
              stepComponent: Step5,
              stepId: "finish",
            },
          ]}
          title="Create Profile"
          subtitle="This information will let us know more about client employee."
          saveButtonClick={saveButtonClick}
          finishButtonClick={finishButtonClick}
        />
      </GridItem>
    </GridContainer>
  );
};
