import React from "react";
import { useForm } from "hooks/useForm/useForm";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  ...customSelectStyle,
};

const useStyles = makeStyles(styles);

const Step3 = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [{ streetname, streetno, city }, handleInputChange] = useForm({
    streetname: "",
    streetno: "",
    city: "",
  });
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    state: {
      streetname,
      streetno,
      city,
    },
  }));
  const sendState = () => {
    //console.log(streetname, streetno, city);
    return {
      streetname,
      streetno,
      city,
    };
  };
  const isValidated = () => {
    return true;
  };
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12}>
        <h4 className={classes.infoText}>Branch office of client employee</h4>
      </GridItem>
      <GridItem xs={12} sm={7}>
        <CustomInput
          labelText="Street Name"
          id="streetname"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: "streetname",
            onChange: handleInputChange,
            value: streetname,
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={3}>
        <CustomInput
          labelText="Street No."
          id="streetno"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: "streetno",
            onChange: handleInputChange,
            value: streetno,
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={5}>
        <CustomInput
          labelText="City"
          id="city"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: "city",
            onChange: handleInputChange,
            value: city,
          }}
        />
      </GridItem>
    </GridContainer>
  );
});

Step3.displayName = "Step3";

export default Step3;
