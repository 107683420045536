import { types } from "lib/types";
import axios from "axios";

export const createPurchaseOrder = (purchase, listFiles, token) => {
  let endpoint = "/api/bff/purchase";
  return async (dispatch) => {
    let created = {};
    await axios
      .post(
        endpoint,
        {
          files: listFiles,
          user: {
            id_user: purchase.employee.id,
            email: purchase.employee.name,
          },
          netAmount: purchase.netAmount,
          deliveryDate: purchase.delivery,
          numOrder: purchase.numOfficeGuide,
        },
        {
          headers: { "authorization-token": token },
        }
      )
      .then((result) => {
        console.log("result axios: " + JSON.stringify(result.data));
        created = result.data.created;
      })
      .catch((error) => {
        console.error("error: " + error);
      });
    if (created !== null && created !== undefined) {
      dispatch(createPurchase(true));
    } else {
      dispatch(createPurchase(false));
    }
  };
};

const createPurchase = (created) => ({
  type: types.createPurchase,
  payload: { created },
});
