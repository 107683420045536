import { types } from "../lib/types";

export const adminReducer = (state = [], action) => {
  switch (action.types) {
    case types.user:
      return {
        state,
      };
    default:
      return state;
  }
};
