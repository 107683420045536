import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import { DashboardRouter } from "./DashboardRouter";
import { AuthRouter } from "./AuthRouter";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";

export const AppRouter = () => {
  const { id } = useSelector((state) => state.auth);
  return (
    <Router>
      <div>
        <Switch>
          <PublicRoute
            component={AuthRouter}
            isAuthenticated={!!id}
            path="/authenticate/login-page"
          />
          <PrivateRoute
            isAuthenticated={!!id}
            Component={DashboardRouter}
            path="/"
          />
        </Switch>
      </div>
    </Router>
  );
};
