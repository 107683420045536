import { combineReducers } from "redux";
import { adminReducer } from "./adminReducer";
import { authReducer } from "./authReducer";
import { companyReducer } from "./companyReducer";
import { employeeReducer } from "./employeeReducer";
import { purchaseReducer } from "./purchaseReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  user: adminReducer,
  company: companyReducer,
  employee: employeeReducer,
  purchase: purchaseReducer,
});
