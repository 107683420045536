import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import { DashAdminStadistic } from "views/Admin/Dashboard/DashAdminStadistic";
import { DashAdminGraphics } from "./DashAdminGraphics";

export const DashboardScreen = () => {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <DashAdminStadistic
            titleHeader="Create Client Company"
            titleButton="Create Client"
            to="/admin/client-company"
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <DashAdminStadistic
            titleHeader="Purchase Order"
            titleButton="Create Purchase Order"
            to="/admin/purchase-order"
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <DashAdminGraphics />
        </GridItem>
      </GridContainer>
    </div>
  );
};
