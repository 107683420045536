import axios from "axios";
import { methodType as methodTYpe } from "./methods";

const urlAuth = "https://api.auth.seth.cl";

export const axiosForLogin = (endpoint, data) => {
  axios
    .post(urlAuth + endpoint, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const axiosConToken = async (endpoint, data, method = "GET", token) => {
  const config = {
    headers: {
      "authorization-token": token,
    },
  };
  switch (method) {
    case methodTYpe.GET:
      return await axiosGet(endpoint, null, config);
    case methodTYpe.POST:
      return axiosPost(endpoint, data, config);
    case methodTYpe.PATCH:
      return axiosPatch(endpoint, data, config);
    case methodTYpe.DELETE:
      return axiosDelete(endpoint, data, config);
  }
};
const axiosGet = async (endpoint, params, config) => {
  try {
    const resp = await axios.get(endpoint, config);
    const { object } = resp.data.response;
    return object;
  } catch (e) {
    return e;
  }
};

const axiosPost = (endpoint, data, config) => {
  axios
    .post(endpoint, data, config)
    .then((response) => {
      return response.data.response;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};
const axiosPatch = (endpoint, method, data, token) => {
  axios
    .patch(endpoint, data, {
      headers: {
        "authorization-token": token,
      },
    })
    .then((response) => {
      return response.data.response;
    })
    .catch((error) => {
      return error.data;
    });
};
const axiosDelete = (endpoint, method, data, token) => {
  axios
    .delete(endpoint, {
      headers: {
        "authorization-token": token,
      },
    })
    .then((response) => {
      return response.data.response;
    })
    .catch((error) => {
      return error.data;
    });
};
