import axios from "axios";
import { types } from "../lib/types";

export const createEmployee = (about, account, address, token) => {
  const { firstname, lastname, tel1, tel2, companyId } = about;
  const { username, password, active, roleId } = account;
  const { streetname, streetno, city } = address;
  const url = "https://api.auth.seth.cl/api/bff";
  const bodyDetails = {
    name: firstname,
    first_name: lastname,
    last_name: "",
    address: streetname + " #" + streetno + ", " + city,
    tel1: tel1,
    tel2: tel2,
  };
  const config = {
    headers: {
      "authorization-token": token,
      "Content-type": "application/json",
    },
  };
  //Return
  return async (dispatch) => {
    if (about !== undefined && account !== undefined && address !== undefined) {
      try {
        const response = await axios.post(
          url + "/userdetails",
          bodyDetails,
          config
        );
        if (response.status === 200) {
          const { id_user_details } = response.data.response.object;
          const bodyUser = {
            username,
            password,
            active,
            id_role: roleId,
            id_user_details,
            id_empresa: companyId,
          };
          const user = await axios.post(url + "/user", bodyUser, config);
          if (user.status === 200) {
            dispatch(create(true));
          } else {
            dispatch(create(false));
          }
        }
      } catch (e) {
        console.error(e);
        dispatch(create(false));
      }
    }
  };
};
export const saveEmployee = (complete, about, account, address) => {
  return async (dispatch) => {
    if (complete) {
      dispatch(save(complete, about, account, address));
    }
  };
};

const save = (complete, about, account, address) => ({
  type: types.saveEmployee,
  payload: { complete, about, account, address },
});
const create = (created) => ({
  type: types.createEmployee,
  payload: { created },
});
