import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ReactTable from "components/ReactTable/ReactTable";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import Snackbar from "components/Snackbar/Snackbar";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle";
import Close from "@material-ui/icons/Close";
import Dvr from "@material-ui/icons/Dvr";

//import axios from "axios";
//import { useForm } from "hooks/useForm/useForm";
import { createCompany, updateCompany } from "actions/company";
import { axiosConToken } from "../../../lib/axiosServices";

const useStyles = makeStyles(styles);

export const ClientList = () => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [nameState, setNameState] = useState("");
  const [rutState, setRutState] = useState("");
  const [giroState, setGiroState] = useState("");
  const [emailState, setEmailState] = useState("");
  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [rut, setRut] = useState("");
  const [giro, setGiro] = useState("");
  const [email, setEmail] = useState("");
  const [web, setWeb] = useState("");

  const getCompanyList = async () => {
    return await axios
      .get("/api/bff/company", {
        headers: { "authorization-token": token },
      })
      .then((resp) => resp.data.response.object);
  };
  useEffect(() => {
    getCompanyList().then((resp) => setData(resp));
  }, []);
  const removeCompany = (companyId) => {
    axiosConToken(
      "/api/bff/company/" + companyId,
      null,
      "DELETE",
      "dms",
      token
    ).then(() => {
      const del = data.filter((company) => companyId !== company.id_empresa);
      setData(del);
    });
  };

  const isValidated = () => {
    if (name !== "" && rut !== "" && giro !== "" && email !== "") {
      return true;
    } else {
      if (nameState !== "success") {
        setNameState("error");
      }
      if (rutState !== "success") {
        setRutState("error");
      }
      if (giroState !== "success") {
        setGiroState("error");
      }
      if (emailState !== "success") {
        setEmailState("error");
      }
    }
    return false;
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    if (isValidated()) {
      dispatch(createCompany(name, rut, giro, email, web, token));
      setShow(true);
      setOpen(false);
      setTimeout(function () {
        setShow(false);
        reset();
      }, 3000);
    }
  };
  const handleUpdate = () => {
    const data = {
      nombre: name,
      rut_empresa: rut,
      giro: giro,
      correo: email,
      pagina: web,
    };
    dispatch(updateCompany(id, data, token));
    setShow(true);
    setOpen(false);
    setTimeout(function () {
      setShow(false);
      reset();
    }, 3000);
  };
  const clientDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create Client</DialogTitle>
        <DialogContent>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  success={nameState === "success"}
                  error={nameState === "error"}
                  labelText="Name"
                  id="name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    autoComplete: "off",
                    name: "name",
                    onChange: (e) => setName(e.target.value),
                    value: name,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  success={rutState === "success"}
                  error={rutState === "error"}
                  labelText="RUT"
                  id="rut"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    autoComplete: "off",
                    name: "rut",
                    onChange: (e) => setRut(e.target.value),
                    value: rut,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  success={giroState === "success"}
                  error={giroState === "error"}
                  labelText="Giro"
                  id="giro"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    autoComplete: "off",
                    name: "giro",
                    onChange: (e) => setGiro(e.target.value),
                    value: giro,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  success={emailState === "success"}
                  error={emailState === "error"}
                  labelText="Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    autoComplete: "off",
                    name: "email",
                    onChange: (e) => setEmail(e.target.value),
                    value: email,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <CustomInput
                  labelText="Url"
                  id="web"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    autoComplete: "off",
                    name: "web",
                    onChange: (e) => setWeb(e.target.value),
                    value: web,
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {!isUpdate ? (
            <Button color={"warning"} onClick={() => handleSave()}>
              Save Client
            </Button>
          ) : (
            <Button color={"warning"} onClick={() => handleUpdate()}>
              Update Client
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };
  const openModalClient = (isTrue = false) => {
    setIsUpdate(isTrue);
    setOpen(true);
  };
  const handleRemoveClick = (id) => {
    //e.preventDefault();
    removeCompany(id);
  };
  const handleUpdateClick = (row) => {
    setId(row.id_empresa);
    setName(row.nombre);
    setEmail(row.correo);
    setGiro(row.giro);
    setRut(row.rut_empresa);
    setWeb(row.pagina);
    openModalClient(true);
  };
  const columns = [
    {
      Header: "Name",
      accessor: "nombre",
    },
    {
      Header: "Rut",
      accessor: "rut_empresa",
    },
    {
      Header: "Giro",
      accessor: "giro",
    },
    {
      Header: "Email",
      accessor: "correo",
    },
    {
      Header: "Web",
      accessor: "pagina",
    },
    {
      Header: "Actions",
      accessor: (row) => {
        return (
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => handleUpdateClick(row)}
              color="warning"
              className="edit"
            >
              <Dvr />
            </Button>
            <Button
              justIcon
              round
              simple
              onClick={() => handleRemoveClick(row.id_empresa)}
              color="danger"
              className="remove"
            >
              <Close />
            </Button>{" "}
          </div>
        );
      },
    },
  ];
  const reset = () => {
    setName("");
    setRut("");
    setEmail("");
    setGiro("");
    setEmail("");
  };
  return (
    <GridContainer>
      {show ? (
        <Snackbar
          place="tr"
          color="success"
          message="Client Created Successfully."
          open={show}
          closeNotification={() => setShow(false)}
          close
        />
      ) : null}
      <GridItem>
        <Card>
          <CardHeader color="primary" text>
            <CardText color="info">
              <h4 className={classes.cardTitle}>List Clients</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={8}></GridItem>
              <GridItem xs={4}>
                <Button
                  color="warning"
                  round
                  className={classes.marginLeft}
                  onClick={() => openModalClient(false)}
                >
                  Create Client
                </Button>
                {clientDialog()}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} md={12}>
                <ReactTable columns={columns} data={data} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
