import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Authenticate from "../layouts/AuthLayout";
//import LoginPage from "../views/Authenticate/LoginPage";

export const AuthRouter = () => {
  return (
    <>
      <Switch>
        <Route path="/authenticate" component={Authenticate} />
        <Redirect from="/" to="/authenticate/login-page" />
      </Switch>
    </>
  );
};
