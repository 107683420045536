// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Folder from "@material-ui/icons/Folder";
import Person from "@material-ui/icons/Person";
//import Description from "@material-ui/icons/Description";
import Business from "@material-ui/icons/Business";
import { EmployeeList } from "../views/Admin/ClientEmployee/EmployeeList";
import { DashboardScreen } from "../views/Admin/Dashboard/DashboardScreen";
import { Client } from "views/Admin/Client/Client";
import { WizardEmployee } from "views/Admin/ClientEmployee/WizardEmployee";
import Purchase from "views/Purchase/PurchaseOrder";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: DashboardScreen,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Client",
    icon: Person,
    state: "clientCollapse",
    views: [
      {
        path: "/client-company",
        name: "Companies",
        mini: "CL",
        component: Client,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Client Employee",
    icon: Business,
    state: "companyCollapse",
    views: [
      {
        path: "/client-employee",
        name: "Create Employee",
        mini: "CC",
        component: WizardEmployee,
        layout: "/admin",
      },
      {
        path: "/list-employee",
        name: "List Employee",
        mini: "LE",
        component: EmployeeList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Orders",
    icon: Folder,
    state: "orderCollapse",
    views: [
      {
        path: "/purchase-order",
        name: "Purchases Order",
        mini: "PO",
        component: Purchase,
        layout: "/admin",
      },
    ],
  },
];
export default dashRoutes;
