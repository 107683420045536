import { types } from "../lib/types";

export const companyReducer = (state = [], action) => {
  switch (action.type) {
    case types.createCompany:
      return {
        loaded: action.payload.loaded,
      };
    case types.updateCompany:
      return {
        loaded: action.payload.loaded,
      };
    case types.deleteCompany:
      return {
        loaded: action.payload.loaded,
      };
    default:
      return {
        state,
      };
  }
};
