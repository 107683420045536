import { types } from "../lib/types";

export const employeeReducer = (state = [], action) => {
  switch (action.type) {
    case types.saveEmployee:
      return {
        complete: action.payload.complete,
        about: action.payload.about,
        account: action.payload.account,
        address: action.payload.address,
      };
    case types.createEmployee:
      return {
        created: action.payload.created,
      };
    default:
      return {
        state,
      };
  }
};
