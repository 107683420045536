import Dashboard from "views/Client/Dashboard.js";
import PurchaseOrder from "views/Client/Po/ListPurchase";
import DetailsPurchase from "views/Client/Po/DetailsPurchase";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Folder from "@material-ui/icons/Folder";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/client",
  },
  {
    collapse: true,
    name: "Orders",
    rtlName: "Orders",
    icon: Folder,
    state: "pageCollapse",
    views: [
      {
        path: "/purchase-order",
        name: "Purchases Order",
        mini: "PO",
        component: PurchaseOrder,
        layout: "/client",
      },
      {
        path: "/details-purchase",
        name: "Detail Purchase",
        mini: "DP",
        component: DetailsPurchase,
        layout: "/client",
      },
    ],
  },
];
export default dashRoutes;
