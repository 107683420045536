export const types = {
  login: "[auth] login",
  logout: "[auth] logout",
  //admin reducer
  user: "[user] create",
  //company reducer
  createCompany: "[company] create",
  deleteCompany: "[company] delete",
  updateCompany: "[company] update",
  //role type
  //create employee
  createEmployee: "[employee] create",
  saveEmployee: "[employee] save",
  roleAdmin: "ADMIN",

  createPurchase: "[purchase] create",
  deletePurchase: "[purchase] delete",
  updatePurchase: "[purchase] update",

  createError: "[error] create",
};
