//import Buttons from "views/Components/Buttons.js";
//import Client from "views/Client/Admin";
import Dashboard from "views/Author/Dashboard.js";
import PurchaseOrder from "views/Purchase/PurchaseOrder.js";
// @material-ui/icons
import Folder from "@material-ui/icons/Folder";
import DashboardIcon from "@material-ui/icons/Dashboard";
//import Description from "@material-ui/icons/Description";
//import Build from "@material-ui/icons/Build";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/author",
  },
  {
    collapse: true,
    name: "Order",
    rtlName: "Order",
    icon: Folder,
    state: "orderCollapse",
    views: [
      {
        path: "/purchase-order",
        name: "Purchase Order",
        mini: "PO",
        component: PurchaseOrder,
        layout: "/author",
      },
      {
        path: "/edit-purchase",
        name: "Edit Purchase",
        mini: "EP",
        component: "",
        layout: "/author",
      },
    ],
  },
];
export default dashRoutes;
