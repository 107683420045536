import { fetchSinToken } from "../lib/fetch";
import { verifyToken } from "../lib/helpers";
import { types } from "../lib/types";

export const startLogin = (username, password) => {
  return async (dispatch) => {
    const resp = await fetchSinToken(
      "api/bff/auth/signin",
      { username, password },
      "POST"
    );
    const body = await resp.json();
    if (body.response.statusCode === 200) {
      const token = body.response.object;
      const { id, role } = await verifyToken(token);
      dispatch(login(id, role, token));
    }
  };
};

export const login = (id, role, token) => ({
  type: types.login,
  payload: {
    id,
    role,
    token,
  },
});

export const logout = () => ({
  type: types.logout,
  payload: {},
});
