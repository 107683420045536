import React from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { ClientList } from "./ClientList";

export const Client = () => {
  return (
    <GridContainer>
      <GridItem sm={12} xs={12}>
        <ClientList />
      </GridItem>
    </GridContainer>
  );
};
