import { types } from "../lib/types";

export const purchaseReducer = (state = [], action) => {
  switch (action.type) {
    case types.createPurchase:
      return {
        created: action.payload.created,
      };
    default:
      return {
        state,
      };
  }
};
