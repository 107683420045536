import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import { InputLabel } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
// @material/icon
import Contacts from "@material-ui/icons/Contacts";

// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { SelectCustom } from "components/Select/SelectCustom";
import { FilesCustom } from "components/Files/FilesCustom";

// Custom Hooks
import { useForm } from "hooks/useForm/useForm";
import { createPurchaseOrder } from "actions/purchase";

//API
import { axiosConToken } from "lib/axiosServices";
import { useCounter } from "hooks/useCounter/useCounter";
import { methodType } from "lib/methods";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { getCompanyById } from "selectors/getClientById";
import { getCompanyEmployeeById } from "selectors/getClientEmployeeById";

const useStyles = makeStyles(styles);
//const useStylesExt = makeStyles(stylesExt);

const initForm = {
  numPurchase: "98298",
  netAmount: "87900",
  numOfficeGuide: "987",
};
const urlAuth = "https://api.auth.seth.cl";
export const PurchaseOrderNew = () => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [
    { numPurchase, netAmount, numOfficeGuide },
    handleInputChange,
  ] = useForm(initForm);
  const { counter, increment, reset } = useCounter(0);
  const [delivery, setDelivery] = useState("02-04-2022");
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState({});
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState({});
  const [selectedFile, setSelectedFile] = useState("");
  const [listSelectedFile, setListSelectedFile] = useState([]);
  const [listComponentFiles] = useState([]);

  useEffect(async () => {
    const list = [];
    const object = await axiosConToken(
      methodType.endPointCompany,
      null,
      methodType.GET,
      token
    );
    //console.log("useEffect: " + JSON.stringify(object));
    if (object) {
      if (Array.isArray(object)) {
        object.map((element) => {
          list.push({
            id: element.id_empresa,
            name: element.nombre,
          });
        });
        setCompanies(list);
      }
    } else {
      alert("error to show the list companies");
    }
    reset();
  }, []);
  useEffect(() => {
    const list = [];
    if (selectedFile) {
      list.push(selectedFile);
      setListSelectedFile(list);
    }
  }, [selectedFile]);
  const handleSubmit = () => {
    const purchase = {
      numPurchase,
      netAmount,
      numOfficeGuide,
      delivery,
      employee,
    };
    dispatch(createPurchaseOrder(purchase, listSelectedFile, token));
    reset();
  };
  const handleDelivery = (e) => {
    setDelivery(e.format("DD-MM-YYYY"));
  };

  const handleListCompanies = async (e) => {
    e.preventDefault();
    const list = [];
    if (e.target.value) {
      const companyFiltered = getCompanyById(companies, Number(e.target.value));
      setCompany({
        id: companyFiltered.id,
        name: companyFiltered.name,
      });
      const object = await axiosConToken(
        urlAuth + methodType.endPointUserCompany + companyFiltered.id,
        null,
        methodType.GET,
        token
      );
      if (object) {
        if (Array.isArray(object)) {
          object.map((element) => {
            list.push({
              id: element.id_user,
              name: element.username,
            });
          });
          setEmployees(list);
        }
      }
    }
  };
  const handleListEmployee = (e) => {
    e.preventDefault();
    if (e.target.value) {
      const employeeFiltered = getCompanyEmployeeById(
        employees,
        Number(e.target.value)
      );
      setEmployee(employeeFiltered);
    }
  };
  const handleOnChangeFiles = (e) => {
    e.preventDefault();
    console.log("input file");
    console.log(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
  };
  const handleOnClick = (file) => {
    const list = [];
    if (file) {
      console.log("current: ", file);
      list.push(file);
    }
    setListSelectedFile(list);
  };
  const handleOnClickAddFiles = () => {
    //e.preventDefault();
    increment();
    listComponentFiles.push(
      <FilesCustom
        key={counter}
        handleOnClick={handleOnClick}
        handleOnChange={handleOnChangeFiles}
        file={selectedFile}
      />
    );
  };

  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="info">
          <Contacts />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>Create Purchase Order</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText="N° Purchase Order"
              id="numPurchase"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "numPurchase",
                type: "text",
                onChange: handleInputChange,
                autoComplete: "off",
                value: numPurchase,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText="Net Amount"
              id="netAmount"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "netAmount",
                type: "text",
                onChange: handleInputChange,
                autoComplete: "off",
                value: netAmount,
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <InputLabel className={classes.label}>Delivery date</InputLabel>
            <br />
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                onChange={handleDelivery}
                inputProps={{
                  placeholder: "Delivery Date",
                  value: delivery,
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText="N° Office Guide"
              id="numOfficeGuide"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "numOfficeGuide",
                type: "text",
                autoComplete: "off",
                onChange: handleInputChange,
                value: numOfficeGuide,
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem sm={6}>
            <FormControl fullWidth>
              {companies && companies.length > 0 ? (
                <SelectCustom
                  placeholder="Choose Company Client"
                  name="listCompanies"
                  list={companies}
                  handleOnChange={handleListCompanies}
                />
              ) : null}
            </FormControl>
          </GridItem>
          <GridItem sm={6}>
            <FormControl fullWidth>
              {company && employees.length > 0 ? (
                <SelectCustom
                  placeholder="Choose Client Employee"
                  name="listEmployees"
                  list={employees}
                  handleOnChange={handleListEmployee}
                />
              ) : null}
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <FilesCustom
                state={selectedFile}
                handleOnClick={handleOnClick}
                handleOnChange={handleOnChangeFiles}
              />
              {counter && listComponentFiles.length > 0
                ? listComponentFiles.map((element) => element)
                : null}
            </FormControl>
          </GridItem>
          <GridItem md={6}>
            <br />
            <Button
              color="warning"
              className={classes.registerButton}
              onClick={handleOnClickAddFiles}
            >
              Add More File
            </Button>
          </GridItem>
        </GridContainer>
      </CardBody>
      <CardFooter>
        <Button
          color="info"
          className={classes.registerButton}
          onClick={handleSubmit}
        >
          Save
        </Button>
        <FormLabel className={classes.labelHorizontal}>
          Create Order Quote
        </FormLabel>
      </CardFooter>
    </Card>
  );
};
