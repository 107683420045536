export const methodType = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  DELETE: "DELETE",

  endPointCompany: "/api/bff/company",
  endPointPurchase: "/api/bff/purchase",
  endPointUser: "/api/bff/user",
  endPointUserInfo: "/api/bff/user/info/",
  endPointUsersCompany: "/api/bff/user/info/empresa/",
  endPointUserCompany: "/api/bff/user/empresa/",
};
