import React, { useEffect, useState } from "react";

import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ReactTable from "components/ReactTable/ReactTable";

import { SelectCustom } from "components/Select/SelectCustom";
import { useSelector } from "react-redux";
import { axiosConToken } from "lib/axiosServices";
import { methodType } from "lib/methods";
const useStyles = makeStyles(styles);
const urlAuth = "https://api.auth.seth.cl";

export const EmployeeList = () => {
  const { token } = useSelector((state) => state.auth);
  const [companies, setCompanies] = useState([]);
  const [employees, setEmployees] = useState([]);
  const classes = useStyles();
  useEffect(async () => {
    const list = [];
    const object = await axiosConToken(
      methodType.endPointCompany,
      null,
      methodType.GET,
      token
    );
    //console.log("useEffect", JSON.stringify(response));
    if (object) {
      if (Array.isArray(object)) {
        object.map((element) => {
          list.push({
            id: element.id_empresa,
            name: element.nombre,
          });
        });
        setCompanies(list);
      }
    }
  }, []);
  useEffect(() => {
    console.log(employees);
  }, [employees]);
  const handleListCompanies = async (e) => {
    e.preventDefault();
    if (e.target.value) {
      const object = await axiosConToken(
        urlAuth + methodType.endPointUsersCompany + e.target.value,
        null,
        methodType.GET,
        token
      );
      setEmployees(object);
    }
  };
  const columns = [
    {
      Header: "Firstname",
      accessor: "name",
    },
    {
      Header: "Lastname",
      accessor: "first_name",
    },
    {
      Header: "Address Location",
      accessor: "address",
    },
    {
      Header: "System Role",
      accessor: "rolename",
    },
    {
      Header: "Phone",
      accessor: "tel1",
    },
    {
      Header: "Actions",
      accessor: () => {},
    },
  ];
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" text>
            <CardText color="info">
              <h4 className={classes.cardTitle}>List Clients Employees</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridItem md={12}>
              <br />
              <br />
              <GridItem sm={6}>
                <FormControl fullWidth>
                  {companies && companies.length > 0 ? (
                    <SelectCustom
                      placeholder="Choose Company Client"
                      name="listCompanies"
                      list={companies}
                      handleOnChange={handleListCompanies}
                    />
                  ) : null}
                </FormControl>
              </GridItem>
            </GridItem>
            <GridItem md={12}>
              <br />
              <br />
              <ReactTable columns={columns} data={employees} />
            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
