import React from "react";
//import { useDispatch } from "react-redux";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import { PurchaseOrderList } from "./PurchaseOrderList";

import { PurchaseOrderNew } from "./PurchaseOrderNew";

export default function PurchaseOrder() {
  //const dispatch = useDispatch();
  React.useEffect(() => {}, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={10} md={8}>
        <PurchaseOrderNew />
      </GridItem>
      <GridItem xs={12} sm={10} md={7}></GridItem>
    </GridContainer>
  );
}
