import Button from "@material-ui/core/Button";
import * as Props from "prop-types";
import React from "react";

export const FilesCustom = ({ file, handleOnClick, handleOnChange }) => {
  return (
    <>
      <br />
      <Button
        variant="contained"
        component="label"
        color="primary"
        onClick={() => handleOnClick(file)}
      >
        {" "}
        Upload a file
        <input type="file" hidden onChange={(e) => handleOnChange(e)} />
      </Button>
    </>
  );
};

FilesCustom.propTypes = {
  file: Props.any,
  handleOnClick: Props.func,
  handleOnChange: Props.func,
};
