import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { useSelector } from "react-redux";
import { ErrorStep } from "./ErrorStep";
import { SuccessStep } from "./Sucess";
import { Link } from "react-router-dom";

const Step5 = React.forwardRef((props, ref) => {
  const { created } = useSelector((state) => state.employee);
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
  }));
  const isValidated = () => {
    return false;
  };
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12}>
        {created ? <SuccessStep /> : <ErrorStep />}
        <Link to="/admin/client-employee">Go to start</Link>
      </GridItem>
    </GridContainer>
  );
});

Step5.displayName = "Step5";

export default Step5;
