import * as Props from "prop-types";
import React from "react";
import { InputLabel, MenuItem, Select } from "@material-ui/core";

export const SelectCustom = ({ placeholder, name, list, handleOnChange }) => {
  return (
    <>
      <InputLabel htmlFor="">{placeholder}</InputLabel>
      <Select
        defaultValue={``}
        inputProps={{
          name: name,
          id: name,
        }}
        onChange={handleOnChange}
      >
        {list.map((object, index) => {
          return (
            <MenuItem key={index} value={object.id}>
              {`${object.name}`}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

SelectCustom.propTypes = {
  placeholder: Props.string,
  name: Props.string,
  list: Props.array,
  handleOnChange: Props.func,
};
