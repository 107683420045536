import React from "react";
import { Route } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import login from "assets/img/sobek/login.jpg";
import lock from "assets/img/lock.jpeg";

import LoginPage from "views/Authenticate/LoginPage.js";

const useStyles = makeStyles(styles);

export default function Pages() {
  //const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getBgImage = () => {
    if (window.location.pathname.indexOf("/authenticate/login-page") !== -1) {
      return login;
    } else if (
      window.location.pathname.indexOf("/authenticate/lock-screen-page") !== -1
    ) {
      return lock;
    }
  };

  return (
    <div>
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{ backgroundImage: "url(" + getBgImage() + ")" }}
        >
          <Route
            path="/authenticate/login-page"
            component={LoginPage}
            key={1}
          />
          <Footer white />
        </div>
      </div>
    </div>
  );
}
