import axios from "axios";
import { types } from "../lib/types";

export const createCompany = (name, rut, giro, email, web, token) => {
  const url = "/api/bff/company";
  const body = {
    nombre: name,
    rut_empresa: rut,
    giro: giro,
    correo: email,
    pagina: web,
  };
  const config = {
    headers: {
      "authorization-token": token,
      "Content-type": "application/json",
    },
  };
  //console.table(url, body, token);
  return async (dispatch) => {
    axios
      .post(url, body, config)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(create(true));
        } else {
          dispatch(create(false));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const deleteCompany = (id, token) => {
  const url = "/api/bff/company/";
  const config = {
    headers: {
      "authorization-token": token,
      "Content-type": "application/json",
    },
  };
  return async (dispatch) => {
    await axios.delete(url + id, config).then((resp) => {
      if (resp.status === 200) {
        dispatch(remove(true));
      }
    });
  };
};

export const updateCompany = (id, data, token) => {
  const url = "/api/bff/company/";
  const config = {
    headers: {
      "authorization-token": token,
      "Content-type": "application/json",
    },
  };
  return async (dispatch) => {
    await axios.patch(url + id, data, config).then((resp) => {
      if (resp.status === 200) {
        dispatch(update(true));
      } else {
        dispatch(update(false));
      }
    });
  };
};

const create = (created) => ({
  type: types.createCompany,
  payload: {
    loaded: created,
  },
});

const remove = (deleted) => ({
  type: types.deleteCompany,
  payload: {
    loaded: deleted,
  },
});

const update = (updated) => ({
  type: types.updateCompany,
  payload: {
    loaded: updated,
  },
});
