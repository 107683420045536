import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
//import LoginPage from "../views/Authenticate/LoginPage";

export const PublicRoute = (props) => {
  const { isAuthenticated, component: Component, ...rest } = props;
  return (
    <>
      <Route
        {...rest}
        component={(props) =>
          !isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
        }
      />
    </>
  );
};
/*return (
 <Route
   {...rest}
   component={(props) =>
     !isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
   }
 />
);
};*/
PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};
