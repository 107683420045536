import Timeline from "@material-ui/icons/Timeline";
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Money from "@material-ui/icons/Money";
import NotificationsActive from "@material-ui/icons/NotificationsActive";
import LocalOffer from "@material-ui/icons/LocalOffer";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import ChartistGraph from "react-chartist";
import { colouredLinesChart } from "../../variables/charts";
const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="warning" stats icon>
              <p className={classes.cardCategory}>
                Numero de Ordenes de Compra
              </p>
              <h4 className={classes.cardTitle}>21</h4>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <a
                  href="/client/purchase-order"
                  onClick={(e) => e.preventDefault()}
                >
                  Numero de Ordenes de Compra
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Money />
              </CardIcon>
              <p className={classes.cardCategory}>Monto neto en Compras</p>
              <h4 className={classes.cardTitle}>21.356.400</h4>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Money />
                Monto neto en Compras
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <NotificationsActive />
              </CardIcon>
              <p className={classes.cardCategory}>Pending Orders</p>
              <h4 className={classes.cardTitle}>5</h4>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                All Pending Orders Purchase
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <br />
      <h3 align="center"> Graficos</h3>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Grafica <small>- Montos netos</small>
              </h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                data={colouredLinesChart.data}
                type="Line"
                options={colouredLinesChart.options}
                listener={colouredLinesChart.animation}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
