import React from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";

import stylesAdmin from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
const useStylesAdmin = makeStyles(stylesAdmin);

export const DashAdminStadistic = (props) => {
  //console.log(props);
  const { titleHeader, titleButton, to } = { ...props };
  const classes = useStylesAdmin();
  return (
    <Card>
      <CardHeader color="warning" stats icon>
        <p className={classes.cardCategory}>{titleHeader}</p>
      </CardHeader>
      <CardFooter>
        <Button color="info" round className={classes.marginRight}>
          <Link to={to}>{titleButton}</Link>
        </Button>
      </CardFooter>
    </Card>
  );
};
