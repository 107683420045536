import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthorLayout from "../layouts/AuthorLayout";
import AdminLayout from "../layouts/AdminLayout";
import ClientLayout from "../layouts/ClientLayout";

export const DashboardRouter = () => {
  const { role } = useSelector((state) => state.auth);
  return (
    <>
      <Switch>
        {role === "admin" && <Route path="/admin" component={AdminLayout} />}
        {role === "admin" && <Redirect from="/" to="/admin/dashboard" />}
        {role === "author" && <Route path="/author" component={AuthorLayout} />}
        {role === "author" && <Redirect from="/" to="/author/dashboard" />}
        {role === "client" && <Route path="/client" component={ClientLayout} />}
        {role === "client" && <Redirect from="/" to="/client/dashboard" />}
      </Switch>
    </>
  );
};
