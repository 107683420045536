import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";
import stylesAdmin from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
const useStylesAdmin = makeStyles(stylesAdmin);
import { simpleBarChart } from "variables/charts";
import CardText from "../../../components/Card/CardText";

export const DashAdminGraphics = () => {
  const classes = useStylesAdmin();
  return (
    <Card>
      <Card chart>
        <CardHeader color="warning">
          <CardText color="info">
            <h4 className={classes.cardTitle}>Graphics Orders</h4>
          </CardText>
          <ChartistGraph
            className="ct-chart-white-colors"
            data={simpleBarChart.data}
            type="Bar"
            options={simpleBarChart.options}
            responsiveOptions={simpleBarChart.responsiveOptions}
            listener={simpleBarChart.animation}
          />
        </CardHeader>
        <CardBody>
          <h4 className={classes.cardTitle}>Monthly net amount </h4>
          <p className={classes.cardCategory}>Purchase Order Graphic</p>
        </CardBody>
      </Card>
    </Card>
  );
};
