import { types } from "../lib/types";

export const authReducer = (state = [], action) => {
  switch (action.type) {
    case types.login:
      return {
        id: action.payload.id,
        role: action.payload.role,
        token: action.payload.token,
      };
    case types.logout:
      return {
        id: "",
        role: 0,
        token: "",
      };
    default:
      return state;
  }
};
