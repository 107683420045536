import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useForm } from "hooks/useForm/useForm";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import Email from "@material-ui/icons/Email";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
};

const useStyles = makeStyles(styles);

const Step2 = React.forwardRef((props, ref) => {
  const [roleId, setRoleId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [roles, setRoles] = useState([]);
  const [emailState, setemailState] = useState("");
  const [passwordState, setpasswordState] = useState("");
  const [repasswordState, setrepasswordState] = useState("");
  const [checked, setChecked] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const classes = useStyles();
  const [{ email, password, repassword }, handleInputChange] = useForm({
    email: "",
    password: "",
    repassword: "",
  });
  useEffect(() => {
    const url = "https://api.auth.seth.cl/api/bff/role";
    axios
      .get(url, {
        headers: {
          "authorization-token": token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const { object } = response.data.response;
          setRoles(object);
        }
      })
      .catch(() => {
        return null;
      });
  }, [setRoles]);

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    state: {
      username: email,
      password,
      repassword,
      roleId,
      roleName,
      active: checked,
    },
  }));
  const sendState = () => {
    return {
      username: email,
      password,
      repassword,
      roleId: roleId,
      roleName: roleName,
      active: checked,
    };
  };
  const isValidated = () => {
    return true;
  };

  const handleToggle = (value) => {
    setChecked(value);
  };
  const handleRoles = (e) => {
    const rol = roles.find((r) => {
      if (r.id_role === e.target.value) return r;
    });
    setRoleName(rol.name);
    setRoleId(rol.id_role);
  };
  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <h4 className={classes.infoText}>What are you doing? (checkboxes)</h4>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8} lg={8}>
          <GridItem>
            <CustomInput
              success={emailState === "success"}
              error={emailState === "error"}
              labelText={
                <span>
                  Email <small>(required)</small>
                </span>
              }
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "email",
                id: "email",
                onChange: (event) => {
                  if (!verifyEmail(event.target.value)) {
                    setemailState("error");
                  } else {
                    setemailState("success");
                  }
                  handleInputChange(event);
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Email className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          <GridItem>
            <CustomInput
              success={passwordState === "success"}
              error={passwordState === "error"}
              labelText={
                <span>
                  Password <small>(required)</small>
                </span>
              }
              id="password"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: password,
                onChange: (e) => {
                  if (!verifyLength(e.target.value, 3)) {
                    setpasswordState("error");
                  } else {
                    setpasswordState("success");
                  }
                  handleInputChange(e);
                },
                name: "password",
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon className={classes.inputAdornmentIcon}>
                      lock_outline
                    </Icon>
                  </InputAdornment>
                ),
                type: "password",
                autoComplete: "off",
              }}
            />
          </GridItem>
          <GridItem>
            <CustomInput
              success={repasswordState === "success"}
              error={repasswordState === "error"}
              labelText={
                <span>
                  RePassword <small>(required)</small>
                </span>
              }
              id="repassword"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: repassword,
                onChange: (e) => {
                  if (!verifyLength(e.target.value, 3)) {
                    setrepasswordState("error");
                  } else {
                    setrepasswordState("success");
                  }
                  handleInputChange(e);
                },
                name: "repassword",
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon className={classes.inputAdornmentIcon}>
                      lock_outline
                    </Icon>
                  </InputAdornment>
                ),
                type: "password",
                autoComplete: "off",
              }}
            />
          </GridItem>
          <GridItem>
            <FormControl fullWidth>
              <InputLabel htmlFor="">Choose Role</InputLabel>
              <Select
                value={roleId}
                onChange={handleRoles}
                inputProps={{
                  name: "role",
                  id: "role",
                }}
              >
                {roles.map((rol, index) => {
                  return (
                    <MenuItem key={index} value={rol.id_role}>
                      {`${rol.name}`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem>
            <div className={classes.checkboxAndRadio}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={checked}
                    onChange={(e) => handleToggle(e.target.checked)}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="Active"
              />
            </div>
          </GridItem>
        </GridItem>
      </GridContainer>
    </div>
  );
});

Step2.displayName = "Step2";

export default Step2;
