import Money from "@material-ui/icons/Money";
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
//import Store from "@material-ui/icons/Store";
//import ArrowUpward from "@material-ui/icons/ArrowUpward";
//import AccessTime from "@material-ui/icons/AccessTime";
//import Refresh from "@material-ui/icons/Refresh";
//import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
//import ArtTrack from "@material-ui/icons/ArtTrack";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";

//import priceImage1 from "assets/img/clients/e-cl.png";
//import priceImage2 from "assets/img/clients/escondida.png";
//import priceImage3 from "assets/img/clients/fluor.png";
const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>
                Numero de Ordenes de Compra
              </p>
              <h4 className={classes.cardTitle}>
                21 <small>GB</small>
              </h4>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <a
                  href="/client/purchase-order"
                  onClick={(e) => e.preventDefault()}
                >
                  Numero de Ordenes de Compra
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Money />
              </CardIcon>
              <p className={classes.cardCategory}>Monto neto en Compras</p>
              <h4 className={classes.cardTitle}>21.356.400</h4>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Money />
                Monto neto en Compras
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}></GridItem>
      </GridContainer>
      <h3>Client Folder</h3>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <a href="#pablo" onClick={(e) => e.preventDefault()}></a>
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardProductTitle}>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Find Purchase Order
                </a>
              </h4>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="N° Purchase Order"
                  id="noc"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    autoComplete: "off",
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Button color="info"> Find</Button>
              </GridItem>
            </CardBody>
            <CardFooter product>
              <div className={classes.price}>
                <h4>BHP</h4>
              </div>
              <div className={`${classes.stats} ${classes.productStats}`}>
                <Place /> Antofagasta, Chile
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
