import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function DetailsPurchase() {
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={8}>
        <Card>
          <CardHeader color="primary" text>
            <CardText color="info">
              <h4 className={classes.cardTitle}>Purchase Detail</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem>
                <h4>N° Order: </h4> 4556689689
              </GridItem>
              <GridItem>
                <h4>Name Document: </h4>Orden de Compra 4556689689
              </GridItem>
              <GridItem>
                <h4>Create Date: </h4> 28/09/2022
              </GridItem>
              <GridItem>
                <h4>Ownner Folder: </h4> <a href="#">daniel.aguilera@seth.cl</a>
              </GridItem>
            </GridContainer>
            <br />
            <br />
            <h3>Attach Document</h3>
            <GridContainer>
              <GridItem>
                <a href="#pablo">Dossier.pdf</a>
              </GridItem>
              <GridItem>
                <a href="#pablo">Dossier2 .pdf</a>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
