import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomAccordion from "components/Accordion/Accordion";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { useSelector } from "react-redux";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  ...customSelectStyle,
};

const useStyles = makeStyles(styles);

const Step4 = React.forwardRef((props, ref) => {
  const { about, account, address } = useSelector((state) => state.employee);
  const classes = useStyles();
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
  }));
  const isValidated = () => {
    return true;
  };
  const contentDetail = () => {
    return (
      <GridContainer>
        <GridItem md={5}>
          <p>
            {`First Name: 
              ${about === undefined ? "" : about?.firstname}`}
          </p>
        </GridItem>
        <GridItem md={5}>
          <p>{`Last Name: ${about === undefined ? "" : about?.lastname}`}</p>
        </GridItem>
        <GridItem md={5}>
          <p>{`Phone: ${about === undefined ? "" : about?.tel1}`}</p>
        </GridItem>
        <GridItem md={5}>
          <p>{`Cell Phone: ${about === undefined ? "" : about?.tel2}`}</p>
        </GridItem>
      </GridContainer>
    );
  };
  const contentAccount = () => {
    return (
      <GridContainer>
        <GridItem md={5}>
          <p>{`Username: ${account === undefined ? "" : account?.username}`}</p>
        </GridItem>
        <GridItem md={5}>
          <p>{`Role: ${account === undefined ? "" : account?.roleName}`}</p>
        </GridItem>
      </GridContainer>
    );
  };
  const contentAddress = () => {
    return (
      <GridContainer>
        <GridItem md={5}>
          <p>{`Street Name: ${
            address === undefined ? "" : address.streetname
          }`}</p>
        </GridItem>
        <GridItem md={5}>
          <p>{`Street No: ${address === undefined ? "" : address.streetno}`}</p>
        </GridItem>
        <GridItem md={8}>
          <p>{`City: ${address === undefined ? "" : address.city}`}</p>
        </GridItem>
      </GridContainer>
    );
  };

  const content = [
    {
      title: "Employee Details",
      content: contentDetail(),
    },
    {
      title: "Business Address",
      content: contentAddress(),
    },
    {
      title: "User System Info",
      content: contentAccount(),
    },
  ];

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12}>
        <h4 className={classes.infoText}>Info Employee Confirm Save</h4>
      </GridItem>
      <GridItem xs={12} sm={7}>
        <CustomAccordion active={0} collapses={content} />
      </GridItem>
    </GridContainer>
  );
});

Step4.displayName = "Step4";

export default Step4;
