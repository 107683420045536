import { InputLabel, MenuItem, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import React, { useEffect, useState } from "react";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PictureUpload from "components/CustomUpload/PictureUpload.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { useSelector } from "react-redux";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

const useStyles = makeStyles(styles);

const Step1 = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { token } = useSelector((state) => state.auth);
  const [firstname, setfirstname] = React.useState("");
  const [firstnameState, setfirstnameState] = React.useState("");
  const [lastname, setlastname] = React.useState("");
  const [lastnameState, setlastnameState] = React.useState("");
  const [empresaId, setEmpresaId] = useState("");
  const [empresaName, setEmpresaName] = useState("");
  const [empresas, setEmpresas] = useState([]);
  const [tel1, settel1] = React.useState("");
  const [tel1State, settel1State] = React.useState("");
  const [tel2, settel2] = React.useState("");
  const [tel2State, settel2State] = React.useState("");
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    state: {
      firstname,
      firstnameState,
      lastname,
      lastnameState,
      tel1,
      tel1State,
      tel2,
      tel2State,
      company: empresaId,
      companyName: empresaName,
    },
  }));
  const sendState = () => {
    return {
      firstname,
      firstnameState,
      lastname,
      lastnameState,
      tel1,
      tel1State,
      tel2,
      tel2State,
      companyId: empresaId,
      companyName: empresaName,
    };
  };
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const isValidated = () => {
    if (firstnameState === "success" && lastnameState === "success") {
      return true;
    } else {
      if (firstnameState !== "success") {
        setfirstnameState("error");
      }
      if (lastnameState !== "success") {
        setlastnameState("error");
      }
    }
    return false;
  };
  const handleEmpresas = (event) => {
    const company = empresas.find((c) => c.id_empresa === event.target.value);
    setEmpresaName(company.nombre);
    setEmpresaId(company.id_empresa);
  };

  useEffect(() => {
    const url = "/api/bff/company";
    axios
      .get(url, {
        headers: {
          "authorization-token": token,
        },
      })
      .then((response) => {
        const { object } = response.data.response;
        setEmpresas(object);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12}>
        <h4 className={classes.infoText}>
          Let{"'"}s start with the basic information (with validation)
        </h4>
      </GridItem>
      <GridItem xs={12} sm={4}>
        <PictureUpload />
      </GridItem>
      <GridItem xs={12} sm={6}>
        <CustomInput
          success={firstnameState === "success"}
          error={firstnameState === "error"}
          labelText={
            <span>
              First Name <small>(required)</small>
            </span>
          }
          id="firstname"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              if (!verifyLength(event.target.value, 3)) {
                setfirstnameState("error");
              } else {
                setfirstnameState("success");
              }
              setfirstname(event.target.value);
            },
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <Face className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
          }}
        />
        <CustomInput
          success={lastnameState === "success"}
          error={lastnameState === "error"}
          labelText={
            <span>
              Last Name <small>(required)</small>
            </span>
          }
          id="lastname"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              if (!verifyLength(event.target.value, 3)) {
                setlastnameState("error");
              } else {
                setlastnameState("success");
              }
              setlastname(event.target.value);
            },
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <RecordVoiceOver className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={4}>
        <CustomInput
          success={lastnameState === "success"}
          error={lastnameState === "error"}
          labelText={
            <span>
              Phone <small>(required)</small>
            </span>
          }
          id="tel1"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              if (!verifyLength(event.target.value, 3)) {
                settel1State("error");
              } else {
                settel1State("success");
              }
              settel1(event.target.value);
            },
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <RecordVoiceOver className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={6}>
        <CustomInput
          success={tel2State === "success"}
          error={tel2State === "error"}
          labelText={
            <span>
              Cell Phone <small>(required)</small>
            </span>
          }
          id="tel2"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              if (!verifyLength(event.target.value, 3)) {
                settel2State("error");
              } else {
                settel2State("success");
              }
              settel2(event.target.value);
            },
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <Face className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={10}>
        <FormControl fullWidth>
          <InputLabel htmlFor="">Choose Company</InputLabel>
          <Select
            value={empresaId}
            onChange={handleEmpresas}
            inputProps={{
              name: "empresa",
              id: "empresa",
            }}
          >
            {empresas.map((emp, index) => {
              return (
                <MenuItem key={index} value={emp.id_empresa}>
                  {`${emp.nombre}`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </GridItem>
    </GridContainer>
  );
});

Step1.displayName = "Step1";

export default Step1;
